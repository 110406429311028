<script>
import { Headset, VideoPause } from '@element-plus/icons-vue';
import SummaryComponent from '@/components/SummaryComponent.vue';

export default {
  components: { SummaryComponent },
  computed: {
    VideoPause() {
      return VideoPause;
    },
    Headset() {
      return Headset;
    },
  },
  props: {
    playingIndex: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    articles: {
      type: Array,
      required: true,
    },

    categoryIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    playArticleAudio(index) {
      this.$emit('playArticleAudio', index);
    },
  },
};
</script>

<template>
  <div v-if="articles && articles.length > 0">
    <el-card class="box-card">
      <div
        style="
          font-size: 1.5em;
          font-weight: bold;
          text-align: center;
          background-color: #f1f1f1;
          /*box-shadow: var(--el-box-shadow-light);*/
          text-transform: capitalize;
          margin-bottom: 20px;
        "
      >
        {{ category }}
      </div>

      <div v-for="(article, index) in articles" :key="index">
        <el-button
          round
          :type="
            playingIndex.categoryIndex !== categoryIndex ||
            playingIndex.articleIndex !== index
              ? 'primary'
              : 'success'
          "
          :icon="
            playingIndex.categoryIndex !== categoryIndex ||
            playingIndex.articleIndex !== index
              ? Headset
              : VideoPause
          "
          @click="
            playArticleAudio({
              categoryIndex: categoryIndex,
              articleIndex: index,
            })
          "
        >
          {{
            playingIndex.categoryIndex !== categoryIndex ||
            playingIndex.articleIndex !== index
              ? 'Listen'
              : 'Pause'
          }}
        </el-button>
        <SummaryComponent
          :title="article.title"
          :original-url="article.originalUrl"
          :overview="article.overview"
          :summary="article.summary"
          :paraSummary="article.paraSummary"
          :publication="article.publication"
          :category="category"
          :imageUrl="article.imageUrl"
          :about="article.about"
          :sno="index + 1"
        ></SummaryComponent>
        <el-divider v-if="index < articles.length - 1"></el-divider>
      </div>
    </el-card>
    <br />
  </div>
</template>

<style scoped></style>
