<template>
  <div class="p-6 flex flex-col justify-center items-center">
    <a :href="rootSiteURL">
      <img src="/logo.png" class="flex w-28 pb-4" />
    </a>
    <div
      class="text-2xl mb-8 mt-8"
      style="max-width: 450px"
      v-if="otpVerificationResult !== 'pass'"
    >
      Connect <WhatsApp /> with Revisit to save your articles directly from the
      phone.
    </div>
    <div class="bg-white rounded-lg p-8 shadow-lg max-w-lg">
      <div v-if="!otpSent">
        <!--        <h2 class="text-xl font-bold text-gray-800">Basic Details</h2>-->
        <!--        <el-divider></el-divider>-->
        <div class="mb-6">
          <span class="font-bold"> Your Name </span>
          <el-input
            placeholder="Full Name"
            v-model="userName"
            @focus="nameError = false"
          ></el-input>
          <span v-if="nameError" class="text-red-600">
            Name can not be empty.
          </span>
        </div>
        <div>
          <span class="font-bold">
            Your
            <WhatsApp />
            number</span
          >
          <el-input
            placeholder="+1234567890"
            v-model="mobileNo"
            @focus="mobileNoError = false"
          ></el-input>
          <span v-if="mobileNoError" class="text-red-600">{{
            getErrorMessages(mobileNoError)
          }}</span>
          <div class="text-center mt-8">
            <el-button
              type="primary"
              @click="sendOTP()"
              color="#626aef"
              size="large"
              :loading="isLoading"
              >Connect</el-button
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="otpVerificationResult === 'pass'">
          <div>
            <div class="text-6xl text-center mb-6 mt-4 text-green-500">
              <i class="fa-solid fa-badge-check"></i>
            </div>
            <WhatsApp /> connected successfully, please check message from
            ReVisit (+918431225104) to see further instructions.
          </div>
        </div>
        <div v-else>
          <h2 class="text-xl font-bold text-gray-800 mb-6">
            Enter the OTP we just texted to you below
          </h2>
          <el-divider></el-divider>
          <otp-input @otpValue="onOtpInput"></otp-input>
          <div
            class="text-center text-red-600"
            v-if="otpVerificationResult === 'fail'"
          >
            It seems you have entered wrong OTP,Please try again
          </div>
          <div class="text-center mt-8">
            <el-button
              type="primary"
              @click="validateWhatsAppOtp(mobileNo)"
              color="#626aef"
              size="large"
              :disabled="otp.length < 4"
              :loading="otpVerifying"
              >Submit</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from 'firebase/auth';
import {
  doc,
  setDoc,
  getDoc,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';

import { nanoid } from 'nanoid';

import { cleanMobileNo, validatePhoneNumber } from '@/utility/whatsApp';
import OtpInput from '@/components/OTPInput.vue';
import { app } from '@/main';
import WhatsApp from '@/components/WhatsApp.vue';

export default defineComponent({
  components: { WhatsApp, OtpInput },
  data() {
    return {
      rootSiteURL: import.meta.env.VITE_rootSiteURL,
      isLoading: false,
      userName: '',
      userID: nanoid(),
      mobileNo: undefined,
      otp: '',
      otpSent: false,
      mobileNoError: false,
      nameError: false,
      otpVerifying: false,
      otpVerificationResult: undefined,
    };
  },
  created() {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.signedIn = true;
      }
    });
  },
  methods: {
    getErrorMessages(code) {
      switch (code) {
        case 'used':
          return `${this.mobileNo} is already in use, try with new number`;

        case 'format':
          return 'Enter a valid number, including country code (e.g. +16507778888)';
      }
    },
    onOtpInput(otp) {
      this.otp = otp;
      console.log('Final OTP value:', otp);
    },
    async sendOTP() {
      this.isLoading = true;
      if (this.userName.length === 0) {
        this.nameError = true;
        this.isLoading = false;
      }
      const firestore = getFirestore();
      const timestamp = serverTimestamp();
      if (validatePhoneNumber(this.mobileNo)) {
        try {
          let whatsAppDoc = await getDoc(
            doc(firestore, `whatsAppCodes/${cleanMobileNo(this.mobileNo)}`)
          );
          if (!whatsAppDoc.exists() || !whatsAppDoc.data().isVerified) {
            this.otpSent = true;
            await setDoc(
              doc(firestore, `whatsAppCodes/${cleanMobileNo(this.mobileNo)}`),
              {
                generateOTP: timestamp,
                mobileNo: cleanMobileNo(this.mobileNo),
                assignedToUser: this.userID,
                isVerified: false,
                isOnlyWhatsApp: true,
              },
              { merge: true }
            );
            this.isLoading = false;
          } else if (whatsAppDoc.data().isVerified) {
            this.mobileNoError = 'used';
            this.isLoading = false;
          }
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      } else {
        this.mobileNoError = 'format';
        this.isLoading = false;
      }
    },
    async validateWhatsAppOtp() {
      this.otpVerifying = true;
      const verifyWhatsAppOTP = httpsCallable(
        getFunctions(app),
        'verifyWhatsAppOTP'
      );
      const result = await verifyWhatsAppOTP({
        otp: this.otp,
        mobileNo: cleanMobileNo(this.mobileNo),
        userName: this.userName,
        uid: this.userID,
        timezoneOffset: new Date().getTimezoneOffset(),
      }).catch((error) => {
        this.otpVerifying = false;
        this.otpVerificationResult = 'fail';
        console.error(error);
      });
      this.otpVerifying = false;
      if (result.data.success) {
        this.otpVerificationResult = 'pass';
      } else {
        this.otpVerificationResult = 'fail';
      }
    },
    async signInWithGoogle() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    },
    async signOut() {
      const auth = getAuth();
      await signOut(auth);
      this.signedIn = false;
    },
  },
});
</script>

<style>
button {
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #c53030;
}
</style>
