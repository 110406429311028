<template>
  <div className="flex items-center justify-center ">
    <div className="flex space-x-4">
      <input
        ref="input1"
        v-model="input1"
        @input="onInput($event, 1)"
        @keydown="onKeyDown($event, 1)"
        class="w-14 h-12 p-4 text-center bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        maxlength="1"
        type="number"
        autocomplete="off"
      />
      <input
        ref="input2"
        v-model="input2"
        @input="onInput($event, 2)"
        @keydown="onKeyDown($event, 2)"
        class="w-14 h-12 p-4 text-center bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        maxlength="1"
        type="number"
        autocomplete="off"
      />
      <input
        ref="input3"
        v-model="input3"
        @input="onInput($event, 3)"
        @keydown="onKeyDown($event, 3)"
        class="w-14 h-12 p-4 text-center bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        maxlength="1"
        type="number"
        autocomplete="off"
      />
      <input
        ref="input4"
        v-model="input4"
        @input="onInput($event, 4)"
        @keydown="onKeyDown($event, 4)"
        class="w-14 h-12 p-4 text-center bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        maxlength="1"
        type="number"
        min="0"
        max="9"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
    };
  },
  methods: {
    onInput(event, index) {
      const value = event.target.value;
      if (value.length === 1) {
        if (index < 4) {
          const nextInput = this.$refs[`input${index + 1}`];
          nextInput.focus();
          this.$emit('otpValue', '');
        } else {
          console.log(
            'asdasd',
            this.input1.toString() +
              this.input2.toString() +
              this.input3.toString() +
              this.input4.toString()
          );
          this.$emit(
            'otpValue',
            this.input1.toString() +
              this.input2.toString() +
              this.input3.toString() +
              this.input4.toString()
          );
        }
      }
    },
    onKeyDown(event, index) {
      if (event.keyCode === 8 && index > 1) {
        if (index === 4 && this[`input${index}`] !== '') {
          const prevInput = this.$refs[`input${index}`];
          prevInput.focus();
          prevInput.setSelectionRange(1, 1);
          this[`input${index}`] = '';
        } else {
          const prevInput = this.$refs[`input${index - 1}`];
          prevInput.focus();
          prevInput.setSelectionRange(1, 1);
          this[`input${index}`] = '';
        }
      }
    },
  },
  mounted() {
    this.$refs.input1.focus();
  },
};
</script>
