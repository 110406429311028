<script>
import {
  doc,
  getDoc,
  collection,
  getDocs,
  where,
  query,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '@/main.js';
import CategoryComponent from '@/components/CategoryComponent.vue';
import { getDownloadUrlFromPath, getRandomInt } from '@/utility';
import {
  Headset,
  Share,
  VideoPause,
  CopyDocument,
  DArrowLeft,
  DArrowRight,
  Plus,
} from '@element-plus/icons-vue';

export default {
  components: { Plus, CategoryComponent },
  data() {
    return {
      userDetails: {},
      fadeTimer: undefined,
      day: new Date(),
      daySummary: undefined,
      newDate: new Date(),
      loaded: false,
      audioFilesURL: [],
      isArticleAudioPlaying: false,
      playingIndex: {
        categoryIndex: -1,
        articleIndex: -1,
        playCategoryNarration: false,
      },
      staticAudioMeta: {
        musicBed: {
          path: 'radio/music/bed',
          loop: true,
          soundCount: 2,
          audioURLs: [],
          lastPlayedMusicBedIndex: 0,
        },
        introAudio: {
          path: 'radio/audio/intro',
          loop: false,
          soundCount: 7,
          audioURLs: [],
          isPlayedOnce: false,
        },
        outroAudio: {
          path: 'radio/audio/outro',
          loop: false,
          soundCount: 7,
          audioURLs: [],
        },
        startRadioMusic: {
          path: 'radio/music/start',
          loop: false,
          soundCount: 10,
          audioURLs: [],
        },
        introEndMusic: {
          path: 'radio/music/intro_end',
          loop: false,
          soundCount: 5,
          audioURLs: [],
        },
        introBangMusic: {
          path: 'radio/music/intro/bang',
          loop: false,
          soundCount: 1,
          audioURLs: [],
        },
        introLoopMusic: {
          path: 'radio/music/intro/loop',
          loop: true,
          soundCount: 1,
          audioURLs: [],
        },
      },
      musicFiller: 'startRadioMusic',
      isUrlCopied: false,
    };
  },
  async created() {
    let today = new Date();
    let year = this.$route.params.year
      ? this.$route.params.year
      : today.getFullYear();
    let month = this.$route.params.month
      ? this.$route.params.month - 1
      : today.getMonth();
    let date = this.$route.params.date
      ? this.$route.params.date
      : today.getDate();

    this.day.setFullYear(year, month, date);
    this.newDate.setFullYear(year, month, date);
    const docRef = doc(db, 'users', this.$route.params.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      this.userDetails = docSnap.data().userDetails;
      // console.log('Document data:', docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  },
  async mounted() {
    let startDate = this.day;
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate.getTime());
    endDate.setHours(23, 59, 59, 999);
    await getDocs(
      collection(db, 'users', this.$route.params.uid, 'summaries')
    ).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        this.daySummary = [];
        querySnapshot.forEach(async (doc) => {
          let category = doc.id;
          if (category !== 'error') {
            let categoryObj = {
              category: category,
              categoryAudioUrl: await getDownloadUrlFromPath(
                `radio/audio/categories/${category}/${category}_0.mp3`
              ),
              articles: [],
            };
            const summaries = query(
              collection(
                db,
                'users',
                this.$route.params.uid,
                'summaries',
                category,
                'urls'
              ),
              where('dateAdded', '>=', startDate),
              where('dateAdded', '<=', endDate)
            );
            const querySnapshot1 = await getDocs(summaries);
            querySnapshot1.forEach((doc) => {
              // console.log(doc.id, ' => ', doc.data());
              let article = doc.data();
              let articleObj = {
                title: article.html.title,
                summary: article.summary,
                publication: article.html.publisher,
                originalUrl: article.url,
                imageUrl: article.html.image,
                about: article.about,
                paraSummary: article.paraSummary,
                overview: article.overview,
                hash: doc.id,
                articleAudioUrl: false,
              };
              getDownloadUrlFromPath(
                `web_pages/${doc.id}/${doc.id}_0.mp3`
              ).then((url) => {
                articleObj.articleAudioUrl = url;
              });
              categoryObj.articles.push(articleObj);
            });
            if (categoryObj.articles.length > 0) {
              this.daySummary.push(categoryObj);
            }
            this.loaded = true;
          }
        });
      } else {
        this.loaded = true;
      }
    });

    Object.keys(this.staticAudioMeta).forEach((key) => {
      for (let i = 1; i <= this.staticAudioMeta[key].soundCount; i++) {
        getDownloadUrlFromPath(
          `${this.staticAudioMeta[key].path + '/' + i + '.mp3'}`
        ).then((url) => {
          this.staticAudioMeta[key].audioURLs.push(url);
        });
      }
    });
    //https://stackoverflow.com/a/22446616
    this.$refs.musicAudioTrack.addEventListener('timeupdate', function () {
      let buffer = 0.3;
      if (this.currentTime > this.duration - buffer) {
        this.currentTime = 0;
        this.play();
      }
    });
  },
  computed: {
    DArrowRight() {
      return DArrowRight;
    },
    DArrowLeft() {
      return DArrowLeft;
    },
    CopyDocument() {
      return CopyDocument;
    },
    Share() {
      return Share;
    },
    VideoPause() {
      return VideoPause;
    },
    Headset() {
      return Headset;
    },
    noSummary() {
      let count = 0;

      if (this.loaded) {
        if (!this.daySummary) {
          return 1;
        }
        this.daySummary.forEach((article) => {
          if (article.articles.length > 0) {
            count++;
          }
          count += article.articles.length;
        });
        return count === 0;
      } else {
        return 0;
      }
    },
    // isPlaying() {
    //   return !this.$refs.audio.paused;
    // },
  },
  methods: {
    downloadUrlsReady: function (val) {
      this.audioFiles = val;
    },
    customDatePicked: function (val) {
      let d = val.getDate();
      let m = val.getMonth() + 1;
      let y = val.getFullYear();

      this.$router
        .push(`/summary/${this.$route.params.uid}/${d}/${m}/${y}`)
        .then(() => {
          this.$router.go();
        });

      // return `/summary/${this.$route.params.uid}/${d}/${m}/${y}`;
    },
    disabledDate: function (a) {
      return a > new Date();
    },
    nextDay: function () {
      let tomorrow = new Date(this.day);
      tomorrow.setDate(tomorrow.getDate() + 1);

      return tomorrow;
    },
    nextDayURL: function () {
      let tomorrow = this.nextDay();
      let d = tomorrow.getDate();
      let m = tomorrow.getMonth() + 1;
      let y = tomorrow.getFullYear();
      return `/summary/${this.$route.params.uid}/${d}/${m}/${y}`;
    },
    previousDayURL: function () {
      let yesterday = new Date(this.day);
      yesterday.setDate(yesterday.getDate() - 1);

      let d = yesterday.getDate();
      let m = yesterday.getMonth() + 1;
      let y = yesterday.getFullYear();

      return `/summary/${this.$route.params.uid}/${d}/${m}/${y}`;
    },
    sendEmail: async function () {
      const timestamp = serverTimestamp();
      await addDoc(collection(db, 'tasks'), {
        options: {
          newsLetterJson: {
            name: 'Moksh',
            uid: this.$route.params.uid,
            date: {
              day: this.day.getDate(),
              month: this.day.getMonth() + 1,
              year: this.day.getFullYear(),
              dayString: this.dateToYMD(this.day),
            },
            newsLetterData: this.daySummary,
          },
          email: 'mokshgarg75@gmail.com',
        },
        performAt: timestamp,
        status: 'scheduled',
        worker: 'sendNewsletter',
      });
    },
    ordinal_suffix_of: function (i) {
      var j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + 'st';
      }
      if (j === 2 && k !== 12) {
        return i + 'nd';
      }
      if (j === 3 && k !== 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
    dateToYMD: function (date) {
      let strArray = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let d = date.getDate();
      let m = strArray[date.getMonth()];
      let y = date.getFullYear();
      return this.ordinal_suffix_of(d) + ' ' + m + ' ' + y;
    },
    stopAudio() {
      this.playingIndex = {
        categoryIndex: -1,
        articleIndex: -1,
      };
      this.staticAudioMeta.musicBed.lastPlayedMusicBedIndex = 0;

      this.$refs.articleAudio.pause();
      this.isArticleAudioPlaying = false;
      this.$refs.articleAudio.currentTime = 0;

      this.$refs.musicAudioTrack.pause();
      this.$refs.articleAudio.currentTime = 0;
    },
    playMusicAudioTrack(src) {
      try {
        this.$refs.musicAudioTrack.pause();
        // this.$refs.musicAudioTrack.volume = volume;
        this.$refs.musicAudioTrack.currentTime = 0;
        this.$refs.musicAudioTrack.src = src;
        this.$refs.musicAudioTrack.play();
      } catch (err) {
        console.log(err);
      }
    },
    stopMusicAudioTrack() {
      this.$refs.musicAudioTrack.pause();
      this.$refs.articleAudio.currentTime = 0;
    },
    stopMusicAudioTrackWithFade() {
      let myAudio = this.$refs.musicAudioTrack;

      if (myAudio.volume > 0) {
        myAudio.volume -= Math.min(myAudio.volume, 0.2);
        this.fadeTimer = setTimeout(this.stopMusicAudioTrackWithFade, 400);
      } else {
        clearTimeout(this.fadeTimer);
        this.$refs.musicAudioTrack.volume = 1;
      }
    },
    playOneTimeMusicAudioTrack(src) {
      try {
        this.$refs.oneTimeMusicTrack.pause();
        // this.$refs.oneTimeMusicTrack.volume = volume;
        this.$refs.oneTimeMusicTrack.currentTime = 0;
        this.$refs.oneTimeMusicTrack.src = src;
        this.$refs.oneTimeMusicTrack.play();
      } catch (err) {
        console.log(err);
      }
    },
    playMusicAudio: function () {
      let index = getRandomInt(this.staticAudioMeta.musicBed.soundCount);
      while (index === this.staticAudioMeta.musicBed.lastPlayedMusicBedIndex) {
        index = getRandomInt(this.staticAudioMeta.musicBed.soundCount);
      }
      this.staticAudioMeta.musicBed.lastPlayedMusicBedIndex = index;
      this.playMusicAudioTrack(this.staticAudioMeta.musicBed.audioURLs[index]);
    },
    playIntroLoopMusicAudio: function () {
      let index = getRandomInt(this.staticAudioMeta.introLoopMusic.soundCount);
      this.playMusicAudioTrack(
        this.staticAudioMeta.introLoopMusic.audioURLs[index],
        0.2
      );
    },
    playIntroEndMusicAudio: function () {
      this.stopMusicAudioTrackWithFade();
      let index = getRandomInt(this.staticAudioMeta.introEndMusic.soundCount);
      this.playOneTimeMusicAudioTrack(
        this.staticAudioMeta.introEndMusic.audioURLs[index],
        0.4
      );
    },
    playStartRadioMusic: function () {
      try {
        let index = getRandomInt(
          this.staticAudioMeta.startRadioMusic.soundCount
        );

        this.$refs.articleAudio.currentTime = 0;
        this.$refs.articleAudio.src =
          this.staticAudioMeta.startRadioMusic.audioURLs[index];
        this.$refs.articleAudio.play();
        this.isArticleAudioPlaying = true;
      } catch (err) {
        console.log(err);
      }
    },
    playIntroAudio: function () {
      let index = getRandomInt(this.staticAudioMeta.introAudio.soundCount);
      try {
        this.$refs.articleAudio.src =
          this.staticAudioMeta.introAudio.audioURLs[index];
        this.$refs.articleAudio.currentTime = 0;

        this.$refs.articleAudio.play();
        this.isArticleAudioPlaying = true;
      } catch (err) {
        console.log(err);
      }

      // this.playingIndex.categoryIndex = -1;
      // this.playingIndex.articleIndex = -1;
      // this.playingIndex.playCategoryNarration = false;
    },
    playOutroAudio: function () {
      let index = getRandomInt(this.staticAudioMeta.outroAudio.soundCount);
      try {
        this.$refs.oneTimeNarrationTrack.src =
          this.staticAudioMeta.outroAudio.audioURLs[index];
        this.$refs.oneTimeNarrationTrack.currentTime = 0;

        this.$refs.oneTimeNarrationTrack.play();
      } catch (err) {
        console.log(err);
      }
    },
    playIntroAudioMix() {
      this.staticAudioMeta.introAudio.isPlayedOnce = true;
      // this.playIntroLoopMusicAudio();
      this.playIntroAudio();
    },
    playArticleAudio: function (index) {
      if (
        index.categoryIndex === this.playingIndex.categoryIndex &&
        index.articleIndex === this.playingIndex.articleIndex
      ) {
        this.stopAudio();
      } else {
        try {
          if (index.playCategoryNarration) {
            this.playIntroEndMusicAudio();
            this.playingIndex = {
              categoryIndex: index.categoryIndex,
              articleIndex: index.articleIndex,
              playCategoryNarration: false,
            };
            this.$refs.articleAudio.src =
              this.daySummary[index.categoryIndex].categoryAudioUrl;
            this.$refs.articleAudio.currentTime = 0;

            this.$refs.articleAudio.play();
            this.isArticleAudioPlaying = true;
          } else {
            this.playMusicAudio();
            this.playingIndex = {
              categoryIndex: index.categoryIndex,
              articleIndex: index.articleIndex,
            };
            this.$refs.articleAudio.src =
              this.daySummary[index.categoryIndex].articles[
                index.articleIndex
              ].articleAudioUrl;
            this.$refs.articleAudio.currentTime = 0;
            this.$refs.articleAudio.playbackRate = 1.1;
            this.$refs.articleAudio.play();
            this.isArticleAudioPlaying = true;
          }
        } catch (err) {
          console.log('end');
          this.stopAudio();
          this.playOutroAudio();
        }
      }
    },
    nextTrack() {
      // this.staticAudioMeta.introAudio.isPlayedOnce = true;
      if (this.playingIndex.categoryIndex === -1) {
        let index = {
          categoryIndex: 0,
          articleIndex: -1,
          playCategoryNarration: true,
        };
        this.playArticleAudio(index);
      } else {
        if (
          this.playingIndex.articleIndex + 1 <
          this.daySummary[this.playingIndex.categoryIndex].articles.length
        ) {
          let index = {
            categoryIndex: this.playingIndex.categoryIndex,
            articleIndex: this.playingIndex.playCategoryNarration
              ? -1
              : this.playingIndex.articleIndex + 1,
            playCategoryNarration: this.playingIndex.playCategoryNarration,
          };
          this.playArticleAudio(index);
        } else if (
          this.playingIndex.categoryIndex + 1 <
          this.daySummary.length
        ) {
          let index = {
            categoryIndex: this.playingIndex.categoryIndex + 1,
            articleIndex: -1,
            playCategoryNarration: true,
          };
          this.playArticleAudio(index);
        } else {
          let index = {
            categoryIndex: -1,
            articleIndex: -1,
          };
          this.playArticleAudio(index);
        }
      }
    },
    shareViaWebShare() {
      const copy = () => {
        this.isUrlCopied = true;
        navigator.clipboard.writeText(window.location.href);
        setTimeout(() => (this.isUrlCopied = false), 2000);
      };
      if (navigator.share) {
        navigator
          .share({
            title: "Let's Revisit",
            text: 'Checkout my curated articles of the day.',
            url: window.location.href,
          })
          .then(() => {
            // copy();
          })
          .catch((err) => {
            console.error(err);
            // copy();
          });
      } else {
        copy();
      }
    },
  },
};
</script>

<template>
  <el-main>
    <!--  {{ daySummary }}-->
    <!--  {{ staticAudioMeta }}-->
    <!--  {{ playingIndex }}-->
    <img src="/logo.png" width="80" style="display: flex" />

    <div style="text-align: center; width: fit-content; margin: auto">
      <el-alert type="success" :closable="false">
        <a
          style="
            color: #4caf50;
            font-weight: bold;
            font-size: 1.5em;
            vertical-align: 6px;
          "
          href="https://chrome.google.com/webstore/detail/revisit/diahhihedjgkipjillkdkojjkihaambf"
          target="_blank"
        >
          <el-icon style="vertical-align: -2px"><Plus /></el-icon>
          <!--        <el-icon style="vertical-align: -2px"><List /></el-icon>-->
          <!--        <el-icon ><Memo /></el-icon>-->
          <!--        <el-icon ><InfoFilled /></el-icon>-->
          Create your own page and podcast in 60 secs
          <!--        <el-icon style="vertical-align: -2px"><Connection /></el-icon>-->
        </a>
      </el-alert>
      <br />
      <img
        v-if="userDetails.photoURL"
        :src="userDetails.photoURL"
        width="80 "
        style="
          border-radius: 50%;
          display: block;
          text-align: center;
          margin: auto;
        "
      />
      <div class="text-3xl font-bold capitalize mb-10">
        {{
          userDetails && userDetails.displayName
            ? userDetails.displayName.split(' ')[0] + "'s " + 'Curation'
            : ''
        }}
      </div>
    </div>
    <audio ref="articleAudio" @ended="nextTrack">
      Your browser does not support the audio element.
    </audio>

    <audio ref="musicAudioTrack" loop>
      Your browser does not support the audio element.
    </audio>
    <audio ref="oneTimeMusicTrack">
      Your browser does not support the audio element.
    </audio>
    <audio ref="oneTimeNarrationTrack">
      Your browser does not support the audio element.
    </audio>

    <div style="text-align: center">
      <a
        :href="$router.resolve(previousDayURL()).href"
        style="margin-right: 10px"
        ><el-button :icon="DArrowLeft" color="#626aef" circle> </el-button
      ></a>
      <div style="font-weight: bold; text-align: center; display: inline">
        <el-date-picker
          @change="customDatePicked"
          v-model="newDate"
          type="date"
          placeholder="Pick a day"
          format="MMM D, YYYY"
          :editable="false"
          :clearable="false"
          :disabled-date="disabledDate"
          style="width: 150px"
          size="large"
        />
      </div>
      <a
        style="margin-left: 10px"
        :href="$router.resolve(nextDayURL()).href"
        v-if="!disabledDate(nextDay())"
        ><el-button :icon="DArrowRight" color="#626aef" circle> </el-button
      ></a>
    </div>

    <div v-if="!noSummary" style="margin-top: 35px">
      <div v-if="daySummary && daySummary.length > 0">
        <el-button
          round
          :type="!isArticleAudioPlaying ? 'primary' : 'success'"
          :icon="!isArticleAudioPlaying ? Headset : VideoPause"
          @click="
            isArticleAudioPlaying
              ? stopAudio()
              : !this.staticAudioMeta.introAudio.isPlayedOnce
              ? playIntroAudioMix()
              : playArticleAudio({
                  categoryIndex:
                    playingIndex.categoryIndex === -1 &&
                    playingIndex.articleIndex === -1
                      ? 0
                      : playingIndex.categoryIndex,
                  articleIndex:
                    playingIndex.categoryIndex === -1 &&
                    playingIndex.articleIndex === -1
                      ? -1
                      : playingIndex.articleIndex,
                  playCategoryNarration: true,
                })
          "
        >
          {{ !isArticleAudioPlaying ? 'Listen All' : 'Pause' }}
        </el-button>
        <el-button
          :type="isUrlCopied ? 'warning' : 'success'"
          style="float: right"
          :icon="isUrlCopied ? CopyDocument : Share"
          @click="shareViaWebShare"
          round
        >
          {{ isUrlCopied ? 'URL Copied' : 'Share' }}
        </el-button>

        <br />
        <br />
        <CategoryComponent
          @playArticleAudio="playArticleAudio"
          v-for="(section, index) in daySummary"
          :key="index"
          :articles="section.articles"
          :category="section.category"
          :category-index="index"
          :playing-index="playingIndex"
        ></CategoryComponent>
      </div>
      <div style="width: 50vw; margin: 20px auto 0 auto" v-else>
        <el-skeleton :rows="5" />
        <br />
      </div>
    </div>

    <div v-else style="margin-top: 20px">
      <el-card><h2>😔 No summaries found.</h2> </el-card>
      <br />
    </div>
    <div style="text-align: center">
      <a
        :href="$router.resolve(previousDayURL()).href"
        style="margin-right: 10px"
        ><el-button :icon="DArrowLeft" color="#626aef" circle> </el-button
      ></a>
      <div style="font-weight: bold; text-align: center; display: inline">
        <el-date-picker
          @change="customDatePicked"
          v-model="newDate"
          type="date"
          placeholder="Pick a day"
          format="MMM D, YYYY"
          :editable="false"
          :clearable="false"
          :disabled-date="disabledDate"
          style="width: 150px"
          size="large"
        />
      </div>
      <a
        style="margin-left: 10px"
        :href="$router.resolve(nextDayURL()).href"
        v-if="!disabledDate(nextDay())"
        ><el-button :icon="DArrowRight" color="#626aef" circle> </el-button
      ></a>
    </div>
    <!--  <div style="text-align: center">-->
    <!--    <a :href="$router.resolve(previousDayURL()).href" style="margin-right: 10px"-->
    <!--      ><el-button color="#626aef" plain>Previous Day</el-button></a-->
    <!--    >-->
    <!--    <a-->
    <!--      :href="$router.resolve(nextDayURL()).href"-->
    <!--      v-if="!disabledDate(nextDay())"-->
    <!--      ><el-button color="#626aef" plain>Next Day</el-button></a-->
    <!--    >-->
    <!--  <el-button @click="sendEmail"> Email Me </el-button>mokshgarg75@gmail.com-->

    <!--  </div>-->

    <!--  <router-link :to="nextDayURL()">Next Day</router-link>-->
  </el-main>
</template>
