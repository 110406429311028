import { getDownloadURL, getStorage, ref } from 'firebase/storage';

export const getDownloadUrlFromPath = async (path) => {
  const storage = getStorage();
  let pathReference = ref(storage, path);
  let url = document.location.origin + '/silence.mp3';
  url = await getDownloadURL(pathReference).catch((err) => {
    console.log(err);
    url = document.location.origin + '/silence.mp3';
  });
  if (url) {
    return url;
  } else {
    return document.location.origin + '/silence.mp3';
  }
};
