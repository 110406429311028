<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    sno: {
      type: Number,
      required: true,
    },
    publication: {
      type: String,
      required: false,
    },
    category: {
      type: String,
      required: true,
    },
    about: {
      type: String,
      required: true,
    },
    overview: {
      type: String,
      required: true,
    },
    paraSummary: {
      type: String,
      required: true,
    },
    summary: {
      type: Array,
      required: true,
    },
    originalUrl: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
    },
  },
  data() {
    return {
      activeName: 'overview',
    };
  },
  created() {},
};
</script>

<template>
  <div>
    <div style="font-size: 1.3em; font-weight: bold">
      {{ sno }}) {{ title }}
    </div>
    <div style="margin: 5px 0 20px 0; font-weight: bold">
      <el-tag type="info" v-if="publication" style="margin-right: 5px">
        {{ publication }}</el-tag
      >
      <el-tag type="info"> {{ category }}</el-tag>
    </div>
    <div class="summaryImgSummary">
      <img :src="imageUrl" class="summaryImg" v-if="imageUrl" />
      <div>
        <div
          v-if="about"
          style="
            padding: 10px;
            border-radius: 10px;
            background-color: antiquewhite;
            text-align: justify;
          "
        >
          {{ about }}
        </div>
        <br />
        <el-tabs v-model="activeName">
          <el-tab-pane label="Overview" name="overview" v-if="overview">{{
            overview
          }}</el-tab-pane>
          <el-tab-pane v-if="paraSummary" label="Summary" name="summary">
            {{ paraSummary }}</el-tab-pane
          >
          <el-tab-pane
            label="Bullet Points"
            name="bullets"
            v-if="summary && summary.length > 1"
          >
            <ol class="summaryOL p-4" type="a" style="padding: 0 22px">
              <li v-for="(s, index) of summary" :key="index">{{ s }}</li>
            </ol>
          </el-tab-pane>
        </el-tabs>

        <!--        <div v-if="paraSummary">-->
        <!--          {{ paraSummary }}-->
        <!--        </div>-->
        <!--        <div v-else>-->
        <!--          <div v-if="summary.length > 1">-->
        <!--            <ol class="summaryOL" type="a">-->
        <!--              <li v-for="(s, index) of summary" :key="index">{{ s }}</li>-->
        <!--            </ol>-->
        <!--          </div>-->
        <!--          <div v-else>-->
        <!--            {{ summary[0] }}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <a
      :href="originalUrl"
      target="_blank"
      rel="noopener"
      style="text-align: center; width: 100%; display: block; margin: 15px 0"
      ><el-tag effect="plain">Read original article</el-tag></a
    >
  </div>
</template>

<style scoped>
.summaryImg {
  height: 150px;
  object-fit: contain;
  display: block;
  margin: auto auto 25px;
  border-radius: 15px;
}
.summaryOL {
  margin: 15px 0 15px 0;
  padding: 0 15px 0 15px;
  text-align: justify;
}
@media (min-width: 800px) {
  .summaryImgSummary {
    display: flex;
  }
  .summaryImg {
    height: 150px;
    object-fit: contain;
    margin: 0 25px 0 0;
  }
  .summaryOL {
    padding: 0 0 0 15px;
    margin: 0;
  }
}
ol {
  padding: 0;
}
ol > li {
  margin: 0;
  padding: 0 0 0 0.5em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
}

ol > li:before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) '.';
}
</style>
