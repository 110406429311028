export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber.length <= 0) {
    return false;
  }
  let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return regex.test(phoneNumber) === true;
}

export function cleanMobileNo(mobileNo) {
  return mobileNo.replaceAll(' ', '').split('+')[1];
}
